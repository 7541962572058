import { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import DataTableHeadCell from "comps/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "comps/Tables/DataTable/DataTableBodyCell";
import { Autocomplete, Pagination, Stack } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";

function DataTable({
  canSearch,
  table,
  name,
  isSorted,
  noEndBorder,
  tablePage,
  setTablePage,
  entriesPerPage,
  setEntriesPerPage,
  totalLength,
  pagination,
  navigateRow,
  tableMaxHeight,
  rowWidth,
  setSortOrder,
  setSortBy,
  sortBy,
  sortOrder,
}) {
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);
  let naviagte = useNavigate();

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    setPageSize,
    setGlobalFilter,
    state: { globalFilter },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts

  useEffect(() => {
    if (entriesPerPage) setPageSize(entriesPerPage);
    // eslint-disable-next-line
  }, [entriesPerPage]);

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;
    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }
    return sortedValue;
  };

  return (
    <>
      <TableContainer
        sx={{
          boxShadow: "none",
          maxHeight: tableMaxHeight ? tableMaxHeight : page.length > 0 && "calc(100vh - 120px)",
          minHeight: page.length <= 0 ? "420px" : "300px",
        }}
      >
        {canSearch ? (
          <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            {canSearch && (
              <MDBox
                width="100%"
                ml="auto"
                display="flex"
                alignItems="center"
                justifyContent={name ? "space-between" : "flex-end"}
              >
                {name && <MDBox color="headingColor">{name}</MDBox>}
                <MDInput
                  width="12rem"
                  placeholder="Search..."
                  value={search}
                  size="small"
                  onChange={({ currentTarget }) => {
                    setSearch(search);
                    onSearchChange(currentTarget.value);
                  }}
                />
              </MDBox>
            )}
          </MDBox>
        ) : null}

        <Table {...getTableProps()}>
          <MDBox component="thead" sx={{ position: "sticky", top: 0, zIndex: 1 }} bgColor="white">
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <DataTableHeadCell
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                    column={column}
                    {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                    width={column.width ? column.width : "auto"}
                    align={column.align ? column.align : "left"}
                    sorted={setSortedValue(column)}
                    setSortBy={setSortBy}
                    setSortOrder={setSortOrder}
                  >
                    {column.render("Header")}
                  </DataTableHeadCell>
                ))}
              </TableRow>
            ))}
          </MDBox>

          <TableBody {...getTableBodyProps()}>
            {page.map((row, key) => {
              prepareRow(row);
              return (
                <TableRow
                  {...row.getRowProps()}
                  onClick={() => {
                    if (navigateRow) naviagte(`${navigateRow}/${row?.values?.id}`);
                  }}
                  sx={{
                    cursor: "pointer",
                    backgroundColor: row?.original?.isActive === false ? "#F8F8F8" : "",
                    "&:nth-of-type(odd)": {
                      backgroundColor: "#FDEBED",
                    },
                  }}
                >
                  {row.cells.map((cell) => (
                    <DataTableBodyCell
                      rowWidth={rowWidth}
                      noBorder={noEndBorder && rows.length - 1 === key}
                      align={cell.column.align ? cell.column.align : "left"}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </DataTableBodyCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        {page.length <= 0 ? (
          <MDBox
            display="flex"
            alignItems="center"
            flexDirection="row"
            justifyContent="center"
            width="100%"
            height="300px"
            color="text"
            fontSize="15px"
          >
            No Rows
          </MDBox>
        ) : null}
      </TableContainer>
      {pagination && page.length > 0 && (
        <Stack direction="row" spacing={2}>
          <Pagination
            count={Math.ceil(totalLength / entriesPerPage)}
            variant="outlined"
            shape="rounded"
            style={{ margin: 20 }}
            color="info"
            page={tablePage}
            onChange={(event, newPage) => {
              setTablePage(newPage);
            }}
          />
          <MDBox display="flex" alignItems="center">
            <Autocomplete
              disableClearable
              value={entriesPerPage.toString()}
              options={["10", "20", "30", "40", "50"]}
              onChange={(event, newValue) => {
                setEntriesPerPage(parseInt(newValue));
              }}
              size="small"
              sx={{ width: "5rem" }}
              renderInput={(params) => <MDInput {...params} />}
            />
            <MDTypography variant="caption" color="dark">
              &nbsp;&nbsp;Entries per page
            </MDTypography>
          </MDBox>
        </Stack>
      )}
    </>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  canSearch: true,
  isSorted: true,
  noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  canSearch: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
};

export default DataTable;
