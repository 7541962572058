import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import {
  Backdrop,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Icon,
  IconButton,
  TextField,
} from "@mui/material";
import MDInput from "components/MDInput";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";
import MDTypography from "components/MDTypography";
import { Controller, useForm } from "react-hook-form";

function VendorDetails() {
  const navigate = useNavigate();
  let { enqueueSnackbar } = useSnackbar();
  let { vendor, setVendor, setIsEdit, isEdit } = useOutletContext();
  const [isLoading, setIsLoading] = useState(false);
  const [api_key, setApi_key] = useState("");

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
    register,
  } = useForm({
    defaultValues: {
      email: "",
      name: "",
      phone: "",
      isActive: true,
      isApi: true,
      building: "",
      street: "",
      state: "",
      country: "",
      city: "",
      zipCode: "",
    },
  });
  const isActive = watch("isActive");
  const isApi = watch("isApi");

  useEffect(() => {
    if (Object.keys(vendor).length > 0) {
      setValue("email", vendor.email);
      setValue("phone", vendor.phone);
      setValue("name", vendor.name);
      setValue("isActive", vendor.isActive);
      setValue("isApi", vendor.isApi);
      setValue("building", vendor.building);
      setValue("street", vendor.street);
      setValue("country", vendor.country);
      setValue("city", vendor.city);
      setValue("zipCode", vendor.zipCode);
      setValue("state", vendor.state);
    }
  }, [vendor]);

  let { id } = useParams();

  useEffect(() => {
    getData();
    getAPIkey();
  }, [id]);

  let getAPIkey = async () => {
    if (id) {
      if (id !== "new") {
        try {
          let result = await TLinkApi.get(`/vendors/api/key/${id}`);
          setApi_key(result.data.apiKey);
          setIsLoading(false);
        } catch (e) {
          console.log(e);
          setIsLoading(false);
        }
      }
    }
  };

  let getData = async () => {
    if (id) {
      if (id !== "new") {
        setIsLoading(true);
        if (window.location.pathname === `/vendors/${id}/edit`) {
          setIsEdit({
            disabled: false,
            edit: true,
          });
        } else {
          setIsEdit({
            disabled: true,
            edit: false,
          });
        }
        try {
          let result = await TLinkApi.get(`/vendors/${id}`);
          console.log(result);
          setVendor(result.data);
          setIsLoading(false);
        } catch (e) {
          console.log(e);
          setIsLoading(false);
        }
      }
    }
  };

  const save = async (data) => {
    setIsLoading(true);
    let payload = {
      ...data,
    };

    payload.vendorId = id;
    setIsLoading(true);

    try {
      if (isEdit.edit === true) {
        let result = await TLinkApi.put(`/vendors/${id}`, payload);
        setIsEdit({
          disabled: false,
          edit: true,
        });
        setIsLoading(false);
        setVendor({});

        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        navigate(`/vendors/${id}/redirectionType/edit`);
      } else {
        let result = await TLinkApi.post("/vendors", data);
        setIsLoading(false);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        setIsEdit({
          disabled: false,
          edit: true,
        });
        navigate(`/vendors/${result.data.id}/redirectionType/edit`);
        setVendor({});
      }
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.message, {
        variant: "error",
      });
    }
  };
  return (
    <MDBox>
      <form onSubmit={handleSubmit(save)} noValidate>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <MDBox height="700px" bgColor="white" p={2} m={2} borderRadius="16px">
          <MDBox px={2} pb={1} display="flex" justifyContent="flex-end">
            {isEdit.disabled === true && (
              <IconButton
                variant="gradient"
                color="dark"
                size="small"
                onClick={() => {
                  setIsEdit({
                    disabled: false,
                    edit: true,
                  });
                  navigate(`/vendors/${id}/edit`);
                }}
              >
                <Icon>border_color_icon</Icon>
              </IconButton>
            )}
          </MDBox>
          <MDBox
            p={2}
            style={{ border: "1px solid #D9D9D9", borderRadius: "16px" }}
            m={2}
          >
            <MDTypography
              variant="h6"
              fontWeight="medium"
              color="textColor"
              style={{ borderBottom: "1px solid #D9D9D9" }}
            >
              {"Basic Details"}
            </MDTypography>
            <Grid container pt={3} spacing={3}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    required: "Name is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      required
                      type="text"
                      autoComplete="off"
                      {...register("name", {
                        pattern: {
                          value: /^[a-zA-Z ]*$/,
                          message: "Please Enter Valid Character",
                        },
                      })}
                      variant="outlined"
                      label="Name"
                      disabled={isEdit.disabled}
                      error={errors?.name ? true : false}
                      helperText={errors?.name ? errors.name.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: "Email is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      required
                      type="email"
                      {...register("email", {
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Entered value does not match email format",
                        },
                      })}
                      autoComplete="off"
                      variant="outlined"
                      label="email"
                      disabled={isEdit.disabled}
                      error={errors?.email ? true : false}
                      helperText={errors?.email ? errors.email.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="phone"
                  control={control}
                  rules={{
                    required: "Phone is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      required
                      type="number"
                      autoComplete="off"
                      variant="outlined"
                      label="Phone"
                      disabled={isEdit.disabled}
                      error={errors?.phone ? true : false}
                      helperText={errors?.phone ? errors.phone.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ display: "flex" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={isEdit.disabled}
                      checked={isActive}
                      onChange={(e, value) => {
                        setValue("isActive", value);
                      }}
                    />
                  }
                  label={
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="dark"
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;&nbsp;Is Active
                    </MDTypography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={isEdit.disabled}
                      checked={isApi}
                      onChange={(e, value) => {
                        setValue("isApi", value);
                      }}
                    />
                  }
                  label={
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="dark"
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;&nbsp;Is Api
                    </MDTypography>
                  }
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox
            p={2}
            style={{ border: "1px solid #D9D9D9", borderRadius: "16px" }}
            m={2}
          >
            <MDTypography
              variant="h6"
              fontWeight="medium"
              color="textColor"
              style={{ borderBottom: "1px solid #D9D9D9" }}
            >
              {"Address"}
            </MDTypography>
            <Grid container pt={3} spacing={3}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="building"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      type="text"
                      autoComplete="off"
                      variant="outlined"
                      label="Building"
                      disabled={isEdit.disabled}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="street"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      type="text"
                      autoComplete="off"
                      variant="outlined"
                      label="Street"
                      disabled={isEdit.disabled}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="city"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      type="text"
                      autoComplete="off"
                      variant="outlined"
                      {...register("city", {
                        pattern: {
                          value: /^[a-zA-Z ]*$/,
                          message: "Please Enter Valid Character",
                        },
                      })}
                      label="City"
                      disabled={isEdit.disabled}
                      error={errors?.city ? true : false}
                      helperText={errors?.city ? errors.city.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="state"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      type="text"
                      autoComplete="off"
                      {...register("state", {
                        pattern: {
                          value: /^[a-zA-Z ]*$/,
                          message: "Please Enter Valid Character",
                        },
                      })}
                      variant="outlined"
                      label="State"
                      disabled={isEdit.disabled}
                      error={errors?.state ? true : false}
                      helperText={errors?.state ? errors.state.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="country"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      type="text"
                      autoComplete="off"
                      {...register("country", {
                        pattern: {
                          value: /^[a-zA-Z ]*$/,
                          message: "Please Enter Valid Character",
                        },
                      })}
                      variant="outlined"
                      label="Country"
                      disabled={isEdit.disabled}
                      error={errors?.country ? true : false}
                      helperText={
                        errors?.country ? errors.country.message : null
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="zipCode"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      type="number"
                      autoComplete="off"
                      variant="outlined"
                      label="Zip Code"
                      disabled={isEdit.disabled}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox
            p={2}
            style={{ border: "1px solid #D9D9D9", borderRadius: "16px" }}
            m={2}
          >
            <MDTypography
              variant="h6"
              color="textColor"
              style={{ borderBottom: "1px solid #D9D9D9" }}
            >
              {"API Key"}
            </MDTypography>
            <Grid container pt={2} spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  placeholder="API Key"
                  value={api_key}
                  disabled
                />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        {isEdit.disabled !== true && (
          <MDBox
            px={2}
            mb={4}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gap="10px"
          >
            <MDButton
              variant="outlined"
              color="dark"
              onClick={() => {
                navigate("/vendors");
                setIsEdit({
                  disabled: false,
                  edit: false,
                });
                setVendor({});
              }}
              style={{ borderRadius: "2px" }}
            >
              &nbsp;cancel
            </MDButton>
            <MDButton mb={4} variant="gradient" color="info" type="submit">
              Save
            </MDButton>
          </MDBox>
        )}
      </form>
      {isEdit.disabled === true && (
        <MDBox
          px={2}
          mb={4}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap="10px"
        >
          <MDButton
            variant="outlined"
            color="dark"
            onClick={() => {
              navigate("/vendors");
              setIsEdit({
                disabled: false,
                edit: false,
              });
              setVendor({});
            }}
            style={{ borderRadius: "2px" }}
          >
            &nbsp;cancel
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => {
              setIsEdit({
                disabled: false,
                edit: true,
              });
              navigate(`/vendors/${id}/redirectionType/edit`);
            }}
            style={{ borderRadius: "2px" }}
          >
            Save
          </MDButton>
        </MDBox>
      )}
    </MDBox>
  );
}

export default VendorDetails;
