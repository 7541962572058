import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import "../../global.css";
import { useEffect, useState } from "react";
import DashboardCard from "./components/CardCompotents";
import BlueIcon from "assets/images/icons/Icon_Blue.png";
import Dollar from "assets/images/icons/Icon_Dollar.png";
import Right from "assets/images/icons/Icon_Right.png";
import Pouch from "assets/images/icons/Icon_Pouch.png";
import TopClients from "./components/TopClients";
import TopSupplier from "./components/TopSupplier";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import TLinkApi from "services/tlink.api";
import TopSurvey from "./components/TopSurvey";
import MDInput from "components/MDInput";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

function Dashboard() {
  const [tab, setTab] = useState("survey");
  const [cardData, setCardData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const ranges = {
    Today: [moment().subtract(1, "days").startOf("day"), moment().subtract(0, "days").endOf("day")],
    "Last Week": [
      moment().subtract(7, "days").startOf("day"),
      moment().subtract(0, "days").endOf("day"),
    ],
    "Last Month": [
      moment().subtract(30, "days").startOf("day"),
      moment().subtract(0, "days").endOf("day"),
    ],
    "Last Year": [
      moment().subtract(365, "days").startOf("day"),
      moment().subtract(0, "days").endOf("day"),
    ],
  };

  const [timeline, setTimeLine] = useState({
    endDate: moment().subtract(0, "days").endOf("day").format("YYYY-MM-DD"),
    startDate: moment().subtract(1, "days").startOf("day").format("YYYY-MM-DD"),
  });

  const formatCash = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(2) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(2) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(2) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(2) + "T";
  };

  useEffect(() => {
    if (Object.keys(timeline).length > 0) {
      getCardData();
    }
    // eslint-disable-next-line
  }, [timeline]);

  let getCardData = async () => {
    setIsLoading(true);
    try {
      let data = await TLinkApi.post("/dashboards/by-range", timeline);

      setCardData(data.data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          md={12}
          pl={3}
          style={{
            color: "#6E9633",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <DateRangePicker
            initialSettings={{
              endDate: moment().subtract(0, "days").endOf("day"),
              startDate: moment().subtract(1, "days").startOf("day"),
              ranges: ranges,
            }}
            alwaysShowCalendars={false}
            onCallback={(start, end, label) => {
              setTimeLine({
                startDate: moment(start._d).format("YYYY-MM-DD"),
                endDate: moment(end._d).format("YYYY-MM-DD"),
              });
            }}
          >
            <MDBox position="relative" width="250px">
              <MDInput
                ml={2}
                color="text"
                inputProps={{
                  style: {
                    height: "20px",
                    border: "1px solid #D9D9D9",
                    backgroundColor: "white",
                  },
                }}
                size="large"
                fullWidth
                value={
                  moment(timeline.startDate).format("LL") +
                  "  -  " +
                  moment(timeline.endDate).format("LL")
                }
              />
              <KeyboardArrowDownIcon
                fontSize="small"
                sx={{
                  fontSize: 20,
                  cursor: "pointer",
                  position: "absolute",
                  right: "10px",
                  top: "12px",
                }}
              />
            </MDBox>
          </DateRangePicker>
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <DashboardCard
            Colour={
              "linear-gradient(270.34deg, #59BDD7 -3.11%, #1D6B7B 112.08%, rgba(255, 255, 255, 0) 143.14%)"
            }
            Name={"Entrants"}
            nomalWidth={"40px"}
            normalHeight={"40px"}
            nameColour={"rgba(230, 246, 244, 1)"}
            Icon={BlueIcon}
            iconBackground={"rgba(230, 247, 253, 0.2)"}
            Status={"Starts"}
            statusColour={"rgba(230, 246, 244, 1)"}
            Value={
              cardData?.entrants?.starts === undefined ? 0 : formatCash(cardData?.entrants?.starts)
            }
            Maths={"EPC"}
            MathsValue={
              cardData?.entrants?.epc === undefined
                ? 0
                : parseFloat(cardData?.entrants?.epc).toFixed(2)
            }
            hoverBackground="linear-gradient(249.28deg, #59BDD7 -33.01%, #1D6B7B 110.74%, rgba(255, 255, 255, 0) 163.43%)"
            transformatiom="scale(1.3)"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MDBox mb={1.5}>
            <DashboardCard
              Colour={
                "linear-gradient(270.34deg, #64B986 -3.11%, #228F62 101.75%, rgba(255, 255, 255, 0) 143.14%)"
              }
              Name={"Completes"}
              nomalWidth={"40px"}
              normalHeight={"40px"}
              nameColour={"rgba(230, 246, 244, 1)"}
              Icon={Right}
              iconBackground={"rgba(230, 247, 253, 0.2)"}
              Status={"Complete"}
              statusColour={"rgba(230, 246, 244, 1)"}
              Value={
                cardData?.completes?.complete === undefined
                  ? 0
                  : formatCash(cardData?.completes?.complete)
              }
              Maths={"Conversion (%)"}
              MathsValue={
                cardData?.completes?.conversion === undefined
                  ? 0
                  : parseFloat(cardData?.completes?.conversion).toFixed(2)
              }
              hoverBackground="linear-gradient(294.92deg, #64B986 -19.61%, #228F62 104.61%, rgba(255, 255, 255, 0) 156.41%)"
              transformatiom="scale(1.3)"
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MDBox mb={1.5}>
            <DashboardCard
              Colour={
                "linear-gradient(284.95deg, #FFB8B0 -32.23%, #FF6B5F 92.04%, rgba(255, 255, 255, 0) 152.75%)"
              }
              Name={"Revenue"}
              nomalWidth={"40px"}
              normalHeight={"40px"}
              nameColour={"rgba(230, 246, 244, 1)"}
              Icon={Dollar}
              iconBackground={"rgba(230, 247, 253, 0.2)"}
              Status={"Revenue ($)"}
              statusColour={"rgba(230, 246, 244, 1)"}
              Value={
                cardData?.revenue?.revenue === undefined
                  ? 0
                  : formatCash(parseFloat(cardData?.revenue?.revenue).toFixed(2))
              }
              Maths={"CPI (%)"}
              MathsValue={
                cardData?.revenue?.cpi === undefined
                  ? 0
                  : parseFloat(cardData?.revenue?.cpi).toFixed(2)
              }
              hoverBackground="linear-gradient(270.48deg, #F6A9A0 -66.31%, #FB877E 119.26%, rgba(255, 255, 255, 0) 142.82%)"
              transformatiom="scale(1.3)"
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MDBox mb={1.5}>
            <DashboardCard
              Colour={
                "linear-gradient(270.48deg, #B097D8 -66.31%, #99A1DA 119.26%, rgba(255, 255, 255, 0) 142.82%)"
              }
              Name={"Profit"}
              nameColour={"rgba(230, 246, 244, 1)"}
              nomalWidth={"40px"}
              normalHeight={"40px"}
              Icon={Pouch}
              iconBackground={"rgba(230, 247, 253, 0.2)"}
              Status={"Profit ($)"}
              statusColour={"rgba(230, 246, 244, 1)"}
              Value={
                cardData?.profit?.profit === undefined
                  ? 0
                  : formatCash(parseFloat(cardData?.profit?.profit).toFixed(2))
              }
              Maths={"Margin (%)"}
              MathsValue={
                cardData?.profit?.margin === undefined
                  ? 0
                  : parseFloat(cardData?.profit?.margin).toFixed(2)
              }
              hoverBackground="linear-gradient(252.64deg, #AA85E7 -33.44%, #99A1DA 103.37%, rgba(255, 255, 255, 0) 120.74%)"
              transformatiom="scale(1.3)"
            />
          </MDBox>
        </Grid>
      </Grid>

      <MDBox mt={5} mb={5} border="1px solid #D0CDCD" bgColor="white" borderRadius="10px">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <MDBox>
          <MDBox display="flex" alignItems="center" flexDirection="row" mt={2} px={1}>
            <Button style={{ padding: "2px" }}>
              <MDBox
                className="tab"
                onClick={() => setTab("survey")}
                style={{
                  fontWeight: "bold",
                  color: tab === "survey" && "rgba(233, 29, 37, 1)",

                  cursor: "pointer",
                  borderBottom: tab === "survey" ? "4px solid rgba(233, 29, 37, 1)" : "",
                }}
              >
                Top Survey
              </MDBox>
            </Button>
            <Button style={{ padding: "2px" }}>
              <MDBox
                className="tab"
                onClick={() => setTab("client")}
                style={{
                  fontWeight: "bold",
                  color: tab === "client" && "rgba(233, 29, 37, 1)",
                  cursor: "pointer",
                  borderBottom: tab === "client" ? "4px solid rgba(233, 29, 37, 1)" : "",
                }}
              >
                Top Client
              </MDBox>
            </Button>
            <Button style={{ padding: "2px" }}>
              <MDBox
                className="tab"
                onClick={() => setTab("supplier")}
                style={{
                  fontWeight: "bold",
                  color: tab === "supplier" && "rgba(233, 29, 37, 1)",
                  cursor: "pointer",
                  borderBottom: tab === "supplier" ? "4px solid rgba(233, 29, 37, 1)" : "",
                }}
              >
                Top Supplier
              </MDBox>
            </Button>
          </MDBox>

          <hr style={{ border: "1px solid #F0F0F0" }} />

          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              {tab === "survey" && <TopSurvey timeline={timeline} />}
              {tab === "client" && <TopClients timeline={timeline} />}
              {tab === "supplier" && <TopSupplier timeline={timeline} />}
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </>
  );
}
export default Dashboard;
