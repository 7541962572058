import React, { useEffect, useState } from "react";
import {
  TextField,
  Grid,
  Box,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  Chip,
} from "@mui/material";
import ReactDOM from "react-dom";
import MDButton from "components/MDButton";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useForm, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";

export const Modal = ({
  closeModal,
  onSubmit,
  languageMap,
  selectlanguage,
  defaultValue,
  getLanguage,
}) => {
  const [languageList, setLanguageList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  useEffect(() => {
    setLanguageList(
      languageMap?.filter(
        (data) => selectlanguage?.indexOf(String(data.id)) <= -1
      )
    );
  }, [languageMap]);

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
    register,
  } = useForm({
    defaultValues: {
      languageId: "",
      languageIds: [],
      vendorId: "",
      minEpc: "",
    },
  });

  let languageIds = watch("languageIds");

  useEffect(() => {
    setValue("languageId", defaultValue.languageId);
    setValue("minEpc", defaultValue.minEpc);
  }, [defaultValue]);

  const [showAllSelectedLanguages, setShowAllSelectedLanguages] =
    useState(false);

  const handleClose = () => {
    closeModal();
  };

  const save = async (data) => {
    let payload = {};
    setIsLoading(true);
    try {
      if (defaultValue) {
        // payload.id = id;
        payload.isActive = true;
        payload.languagedesc = languageMap?.find(
          (lang) => lang.id == data.languageId
        )?.languagedesc;
        payload.minEpc = data.minEpc;
        payload.languageId = data.languageId;
        let result = await TLinkApi.put(`/vendors/language/${defaultValue?.id}`, payload);
        setIsLoading(false);
        getLanguage();
        handleClose();
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
      } else {
        payload.vendorId = id;
        payload.minEpc = data.minEpc;
        payload.languageIds = data.languageIds;
        let result = await TLinkApi.post("/vendors/languages/", payload);
        setIsLoading(false);
        getLanguage();
        handleClose();
      }
    } catch (e) {
      setIsLoading(false);
      handleClose();
      enqueueSnackbar(e?.message, {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    console.log(languageList);
  }, [languageList]);

  return ReactDOM.createPortal(
    <>
      <Grid
        sx={{
          position: "fixed",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          backgroundColor: "rgba(189, 189, 189,0.7)",
        }}
      ></Grid>
      <Grid
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <Box className="modal">
          <form onSubmit={handleSubmit(save)} noValidate>
            <Box
              sx={{
                height: "auto",
                width: "700px",
                padding: "10px",
                border: 1,
                borderColor: "#828385",
                borderRadius: "10px",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                backgroundColor: "#fcfcfc",
              }}
            >
              <Grid
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "700px",
                }}
              >
                {defaultValue && (
                  <>
                    {" "}
                    <h5 style={{ color: "#595959", margin: "10px" }}>
                      Edit Row
                    </h5>{" "}
                    <hr style={{ backgroundColor: "#0d0d0d", height: "2px" }} />{" "}
                  </>
                )}
                {!defaultValue && (
                  <>
                    {" "}
                    <h5 style={{ color: "#595959", margin: "10px" }}>
                      Add Row
                    </h5>{" "}
                    <hr style={{ backgroundColor: "#0d0d0d", height: "2px" }} />{" "}
                  </>
                )}
                {!defaultValue && (
                  <>
                    <Controller
                      name="languageIds"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          multiple={true}
                          disableCloseOnSelect
                          value={value}
                          sx={{margin: "14px 0px"}}
                          {...register("languageIds", {
                            required: watch("languageIds").length<=0 && "Language is required!",
                          })}
                          onChange={(event, newValue) => {
                            let payload = newValue.map((val) =>
                              val.id ? val.id : val
                            );
                            onChange(payload);
                          }}
                          style={{
                            "& .MuiInputBase-input": {
                              minHeight: "20px",
                            },
                          }}
                          renderTags={(value, getTagProps) => (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  overflowX: "auto",
                                  maxWidth: "90%",
                                  maxHeight: "250px",
                                  WebkitOverflowScrolling: "touch",
                                  scrollbarWidth: "none",
                                  msOverflowStyle: "none",
                                }}
                              >
                                <style>
                                  {`
                                 /* Hide horizontal scrollbar */
                                 div::-webkit-scrollbar {
                                   width: 0;
                                   height: 0;
                                 }
                                 `}
                                </style>
                                {value.slice(0, 3).map((option, index) => (
                                  <Chip
                                    variant="outlined"
                                    label={
                                      languageList?.find(
                                        (data) => data.id == option
                                      )?.languagedesc
                                    }
                                    style={{
                                      height: "20px",
                                    }}
                                    {...getTagProps({ index })}
                                  />
                                ))}
                                {value?.length > 3 &&
                                  !showAllSelectedLanguages && (
                                    <Chip
                                      variant="outlined"
                                      label={`+${value?.length - 3} more`}
                                      style={{
                                        height: "20px",
                                        marginTop: "3px",
                                      }}
                                      onClick={() =>
                                        setShowAllSelectedLanguages(true)
                                      }
                                    />
                                  )}

                                {showAllSelectedLanguages &&
                                  value.slice(3).map((option, index) => (
                                    <Chip
                                      variant="outlined"
                                      label={
                                        languageList?.find(
                                          (data) => data.id == option
                                        )?.languagedesc
                                      }
                                      style={{
                                        height: "20px",
                                      }}
                                      {...getTagProps({ index })}
                                    />
                                  ))}
                              </div>
                            </>
                          )}
                          isOptionEqualToValue={(option, value) =>
                            option?.id == value
                          }
                          options={languageList}
                          getOptionLabel={(option) => option.languagedesc}
                          renderOption={(props, option, { selected }) => {
                            if (
                              props["data-option-index"] === 0 &&
                              languageList.length > 0
                            ) {
                              return (
                                <li style={{ paddingLeft: "16px" }}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        icon={
                                          <CheckBoxOutlineBlankIcon fontSize="small" />
                                        }
                                        checkedIcon={
                                          <CheckBoxIcon fontSize="small" />
                                        }
                                        checked={
                                          value.length === languageList?.length
                                        }
                                        onChange={(event) => {
                                          if (event.target.checked) {
                                            onChange(
                                              languageList?.map(
                                                (option) => option.id
                                              )
                                            );
                                          } else {
                                            onChange([]);
                                          }
                                        }}
                                      />
                                    }
                                    label="Select All"
                                  />
                                </li>
                              );
                            }
                            return (
                              <li {...props}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      icon={
                                        <CheckBoxOutlineBlankIcon fontSize="small" />
                                      }
                                      checkedIcon={
                                        <CheckBoxIcon fontSize="small" />
                                      }
                                      checked={selected}
                                    />
                                  }
                                  label={option.languagedesc}
                                />
                              </li>
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Choose a language"
                              variant="outlined"
                              size="small"
                              error={
                                errors?.languageIds && watch("languageIds").length <= 0
                                  ? true
                                  : false
                              }
                              helperText={
                                errors?.languageIds && watch("languageIds").length <= 0
                                  ? errors?.languageIds.message
                                  : null
                              }
                            />
                          )}
                        />
                      )}
                    />
                  </>
                )}
                {defaultValue && (
                  <TextField
                    label="Language"
                    name="language"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={
                      languageMap?.find(
                        (data) => data.id == watch("languageId")
                      )?.languagedesc
                    }
                    {...register("languageId")}
                    inputProps={{ readOnly: true }}
                  />
                )}
                <TextField
                  value={watch("minEpc")}
                  fullWidth
                  required
                  type="number"
                  autoComplete="off"
                  variant="outlined"
                  {...register("minEpc", {
                    required: "Min EPC is required!",
                    min: {
                      value: 0.0001,
                      message: "Min EPC should be greater than 0!",
                    },
                  })}
                  label="Min EPC"
                  error={errors?.minEpc ? true : false}
                  helperText={
                    errors?.minEpc && errors.minEpc.message !== ""
                      ? errors.minEpc.message
                      : null
                  }
                  InputProps={{
                    inputProps: {
                      step: 0.01,
                    },
                  }}
                  onBlur={(e) => {
                    const roundedValue = parseFloat(e.target.value).toFixed(2);
                    setValue("minEpc", roundedValue);
                  }}
                />
                <Grid sx={{ display: "flex", justifyContent: "right", marginTop: "6px" }}>
                  <MDButton
                    sx={{ margin: "4px" }}
                    color="dark"
                    variant="outlined"
                    onClick={handleClose}
                  >
                    Cancel
                  </MDButton>
                  <MDButton
                    sx={{ margin: "4px" }}
                    color="info"
                    variant="contained"
                    type="submit"
                  >
                    Add
                  </MDButton>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Box>
      </Grid>
    </>,
    document.querySelector("#root")
  );
};
